<div class="containerDialog">
  <div class="header">
    <h1 mat-dialog-title class="mat-dialog-title">{{ dialogData.title | translate }}</h1>
    <span class="spacer"></span>
    <button *ngIf="dialogData.changeConfiguration" class="change-configuration" mat-stroked-button color="primary"
            (click)="dialogRef.close(); dialogData.changeConfiguration.callBack(liabilityForm)"
            [ngClass]="{'readonly-block': permissionService.hasPermission('main.auditorExcludeEditing')}"
            [tabindex]="permissionService.hasPermission('main.auditorExcludeEditing') ? -1 : null">
      {{ dialogData.changeConfiguration.title | translate }} <i [ngClass]="['uil', 'uil-pen']"></i>
    </button>
    <i class="uil uil-multiply" (click)="dialogRef.close()"></i>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="containerContent">
    <div class="question">
      <p>{{ dialogData.description | translate }}</p>
      <div class="containerErrorList" *ngFor="let formConfigurationError of liabilityFormConfigurationErrors">
        <span>&#8226; {{ formConfigurationError }}</span>
      </div>
      <div class="containerWarningList" *ngFor="let warning of liabilityFormConfigurationWarnings">
        <span>&#8226; {{ warning }}</span>
      </div>
    </div>
    <app-dynamic-form [formConfiguration]="liabilityFormConfig" (valueChange)="liabilityFormConfigValueChaned($event)"
                      (valueInit)="liabilityFormConfigValueInit($event)" (errors)="getLiabilityFormConfigErrors($event)"
                      [markAllAsTouched]="triggerMarkAllAsTouched" (buttonClicked)="liabilityButtonClicked()">
    </app-dynamic-form>
    <mat-divider></mat-divider>
    <div class="question"  *ngIf="permissionService.hasPermission('main.possibilityChangeFiscalityRelatedFields') && dialogData.isPersonal">
      <p>{{ 'ç.resource.document-type.fiscalAttestation' | translate }}</p>
      <div class="containerErrorList" *ngFor="let formConfigurationError of fiscalFormConfigurationErrors">
        <span>&#8226; {{ formConfigurationError }}</span>
      </div>
      <div class="containerWarningList" *ngFor="let warning of fiscalFormConfigurationWarnings">
        <span>&#8226; {{ warning }}</span>
      </div>
    </div>
    <app-dynamic-form [formConfiguration]="fiscalFormConfig" (valueChange)="fiscalFormConfigValueChaned($event)"
                      (valueInit)="fiscalFormConfigValueInit($event)" (errors)="getFiscalFormConfigrrors($event)"
                      [markAllAsTouched]="triggerMarkAllAsTouched">
    </app-dynamic-form>
    <div class="question" *ngIf="permissionService.hasPermission('main.allowViewImmovableBalanceLiability')">
      <mat-divider></mat-divider>
      <p></p>
      <div class="containerErrorList" *ngFor="let formConfigurationError of immovableAmountFormConfigurationErrors">
        <span>&#8226; {{ formConfigurationError }}</span>
      </div>
      <div class="containerWarningList" *ngFor="let warning of immovableAmountFormConfigurationWarnings">
        <span>&#8226; {{ warning }}</span>
      </div>
    </div>
    <app-dynamic-form
    *ngIf="permissionService.hasPermission('main.allowViewImmovableBalanceLiability')"
    [formConfiguration]="immovableAmountFormConfig" (valueChange)="immovableAmountFormConfigValueChaned($event)"
    (valueInit)="immovableAmountFormConfigValueInit($event)" (errors)="getImmovableAmountFormConfigrrors($event)"
    [markAllAsTouched]="triggerMarkAllAsTouched">
</app-dynamic-form>
  </div>
  <div mat-dialog-actions class="containerButtons">
    <button mat-button (click)="onCancel()">{{ dialogData.buttons.cancel | translate }}</button>
    <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="isDisabled" *ngIf="!permissionService.hasPermission('main.auditorExcludeEditing')">
      {{ dialogData.buttons.accept | translate }}
    </button>
  </div>
</div>
